import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { StudentService } from '../../../services/student.service';
import { Student } from '../../../models/student.model';
import { ToasterService } from '../../../services/toaster.service';
import { ToasterType } from '../../../models/toaster.model';


@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrl: './student-details.component.scss'
})
export class StudentDetailsComponent implements OnInit {
  student: Student | undefined;
  loading = true;

  constructor(private studentService: StudentService, private toasterService: ToasterService) { }

  ngOnInit(): void {
    this.loadData()
  }

  loadData(): void {
    this.studentService.getStudentDetail().subscribe({
      next: (result) => {
        this.student = result;
        this.loading = false;
      }, 
      error: (error) => {
        this.toasterService.addToaster({type: ToasterType.Error, message: error.error.message})
        this.loading = false;
      }
    })
  }
}
