import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import 'hammerjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignDocumentComponent } from './pages/authenticated/sign-document/sign-document.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './pages/authenticated/dashboard/dashboard.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthenticatedComponent } from './pages/authenticated/authenticated.component';
import { DocumentListComponent } from './pages/authenticated/document-list/document-list.component';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { DocumentComponent } from './pages/authenticated/document/document.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CommonLibModule } from "../../../common-lib/src/lib/common-lib.module";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { StripeRedirectComponent } from './pages/stripe-redirect/stripe-redirect.component';
import { StudentSelectorComponent } from './pages/authenticated/student-selector/student-selector.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { StudentDetailsComponent } from './pages/authenticated/student-details/student-details.component';
import { StudentDetailsFormComponent } from './components/forms/student-details-form/student-details-form.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SuccessToasterComponent } from './components/toasters/success-toaster/success-toaster.component';
import { ErrorToasterComponent } from './components/toasters/error-toaster/error-toaster.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS } from 'projects/admin/src/app/lib/date-format';
import { DatePipe } from '@angular/common';


@NgModule({ declarations: [
        AppComponent,
        SignDocumentComponent,
        NavbarComponent,
        LoginComponent,
        DashboardComponent,
        AuthenticatedComponent,
        DocumentListComponent,
        DocumentComponent,
        BackButtonComponent,
        SideNavigationComponent,
        CalendarComponent,
        StripeRedirectComponent,
        StudentSelectorComponent,
        TermsAndConditionsComponent,
        StudentDetailsComponent,
        StudentDetailsFormComponent,
        SuccessToasterComponent,
        ErrorToasterComponent
    ],
    bootstrap: [AppComponent], 
    imports: [BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgHeroiconsModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    FullCalendarModule,
    HammerModule, 
    CommonLibModule,
    MatDatepickerModule,
    MatSnackBarModule
    ], 
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
        provideHttpClient(withInterceptorsFromDi()),
        { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        DatePipe,
    ] })
export class AppModule { }
