
<form [formGroup]="studentDetailsForm" (ngSubmit)="onSubmit()">
    <div class="p-5 mb-20 flex flex-col gap-4 w-full lg:w-1/2">
        <p class="text-lg text-gray-900 font-semibold">
            Your details
        </p>
        <fc-text-input
            label="First Name"
            formControlName="firstName"
            [required]="true"
            [submitted]="submitted"
            placeholder="Required"        
        />
        <fc-text-input
            label="Last Name"
            formControlName="lastName"
            [required]="true"
            [submitted]="submitted"
            placeholder="Required"        
        />
        <fc-text-input
            label="Email"
            formControlName="email"
            helper="Contact gym to change email"      
        >
        </fc-text-input>
        <fc-text-input class="sm:w-1/2"
            label="Mobile"
            formControlName="mobile"
            [submitted]="submitted"
            [errorMessages]="{pattern: 'Please enter a valid mobile number'}"
        ><device-phone-mobile-outline-icon #icon></device-phone-mobile-outline-icon>
        </fc-text-input>
        <div class="sm:w-1/2">
            <fc-text-input
                [label]="'Date of birth'"
                [matDatepicker]="date"
                formControlName="dateOfBirth"
                helper="Contact gym to change date of birth"
            ></fc-text-input>
            <mat-datepicker touchUi #date></mat-datepicker>
        </div>
        <div class="border-t border-gray-200 h-px"></div>

        <p class="text-lg text-gray-900 font-semibold">
            Emergency contact information
        </p>
        <div formGroupName="emergencyContact" class="flex flex-col gap-4">
            <fc-text-input
                label="Contact First Name"
                formControlName="emergencyFirstName"
                [required]="true"
                [submitted]="submitted"
                placeholder="Required"        
            />
            <fc-text-input
                label="Contact Last Name"
                formControlName="emergencyLastName"
                [required]="true"
                [submitted]="submitted"
                placeholder="Required"        
            />
            <fc-text-input class="sm:w-1/2"
                label="Emergency contact number"
                formControlName="emergencyMobile"
                [required]="true"
                [submitted]="submitted"
                placeholder="Required"
                [errorMessages]="{pattern: 'Please enter a valid mobile number', required: 'Emergency contact number is required'}"
            ><device-phone-mobile-outline-icon #icon></device-phone-mobile-outline-icon>
            </fc-text-input>
            <fc-text-input
                label="Relationship"
                formControlName="emergencyRelationship"
            >
            </fc-text-input>
            <div class="border-t border-gray-200 h-px"></div>
        </div>

        <p class="text-lg text-gray-900 font-semibold">
            Emergency information
        </p>
        <fc-textarea
            label="Notes"
            formControlName="emergencyInformation"
            placeholder="Outline important information the gym should be made aware of. e.g. anything impacting your safety or ability to train."
            [rows]="5"
        />
    </div>
    @if (studentDetailsForm.dirty) {
        <fc-save-footer [loading]="submitting" [error]="submitted && !studentDetailsForm.valid"></fc-save-footer>
    }
</form>