import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DocumentService } from '../../../services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SignStudentDocument } from '../../../models/sign-student-document.model';
import { SignaturePadComponent } from '@fc-lib';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { StudentService } from '../../../services/student.service';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-sign-document',
  templateUrl: './sign-document.component.html',
  styleUrls: ['./sign-document.component.scss']
})
export class SignDocumentComponent implements OnInit {
  #documentVersionId: number | null = null;
  signStudentDocument: SignStudentDocument | undefined;
  submitted = false;
  guardianSignature = false;
  requireDateOfBirth = false;
  studentName = '';
  dateOfBirth: string | undefined;
  submitting = false;
  #signaturePadComponent: SignaturePadComponent | undefined;
  @ViewChild("signaturePad", {static: false}) set signaturePadComponent(component: SignaturePadComponent) {
    if (component) {
      this.#signaturePadComponent = component;
      if (this.showDateOfBirth) {
        component.canvasEl.nativeElement.scrollIntoView();
      }
    }
  }

  get signaturePadComponent(): SignaturePadComponent | undefined {
    return this.#signaturePadComponent;
  }

  get showDateOfBirth() {
    return this.signStudentDocument?.signatureRequired && this.requireDateOfBirth;
  }

  get showSignatureComponent() {
    return this.signStudentDocument?.signatureRequired && (!this.requireDateOfBirth || this.dateOfBirth);
  }

  get showGuardianName() {
    return this.guardianSignature && this.showSignatureComponent;
  }

  get showSignButton() {
    return this.showSignatureComponent && !this.#signaturePadComponent?.signaturePad?.isEmpty();
  }

  constructor(private documentService: DocumentService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private studentService: StudentService, private ref: ChangeDetectorRef) {}

  signatureForm = this.fb.group({
    signatureName: new FormControl<string>('', Validators.required)
  })

  ngOnInit(): void {
    this.#documentVersionId = Number(this.route.snapshot.paramMap.get('documentVersionId'));
    if (this.#documentVersionId) {
      this.documentService.getDocument(this.#documentVersionId).subscribe((signStudentDocument) => {
        this.signStudentDocument = signStudentDocument;
      });
    }

    this.studentService.getStudentDetail().subscribe({
      next: student => {
        this.studentName = `${student.firstName} ${student.lastName}`;
        if (student.dateOfBirth) {
          this.guardianSignature = moment().diff(student.dateOfBirth, 'years') < 18;
          if (!this.guardianSignature) {
            this.signatureForm.get('signatureName')?.setValue(`${this.studentName}`);
          }
        } else {
          this.requireDateOfBirth = true;
        }
      }
    });
  }

  save() {
    this.submitting = true;
    this.submitted = true;
    if (this.signatureForm.valid) {
      this.documentService.saveDocument(this.#documentVersionId!, this.#signaturePadComponent?.signaturePad.toDataURL()!, this.signatureForm.get('signatureName')?.value!, this.guardianSignature, this.dateOfBirth).subscribe(() => {
        this.submitting = false;
        this.router.navigateByUrl('/document')
      });
    } else {
      this.submitting = false;
    }
  }

  clear() {
    this.#signaturePadComponent?.signaturePad.clear();
  }

  dateChange(date: MatDatepickerInputEvent<Date>) {
    this.dateOfBirth = moment(date.value).format('YYYY-MM-DD');
    this.guardianSignature = moment().diff(this.dateOfBirth, 'years') < 18;
    if (!this.guardianSignature) {
      this.signatureForm.get('signatureName')?.setValue(`${this.studentName}`);
    }

    this.ref.detectChanges();
  }
}
