import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToasterService } from '../../../services/toaster.service';
import { StudentService } from '../../../services/student.service';
import { ToasterType } from '../../../models/toaster.model';
import { Student } from '../../../models/student.model';
import { getDateString } from 'projects/admin/src/app/lib/date-helpers';

@Component({
  selector: 'app-student-details-form',
  templateUrl: './student-details-form.component.html',
  styleUrl: './student-details-form.component.scss'
})
export class StudentDetailsFormComponent implements OnInit {
  @Input() student: Student | undefined;

  submitted = false;
  submitting = false;
  studentDetailsForm = this.fb.group({
    firstName: new FormControl<string>('', Validators.required),
    lastName: new FormControl<string>('', Validators.required),
    email: new FormControl<string|null>({value: null, disabled: true}),
    mobile: new FormControl<string|null>(null, [Validators.pattern(/^(\+\d{2} \d{3} \d{3} \d{3}|\d{10}|\d{4} \d{3} \d{3}|\+\d{11})$/)]),
    dateOfBirth: new FormControl<string|null>({value: null, disabled: true}),
    emergencyContact: this.fb.group({
      emergencyFirstName: new FormControl<string|null>(null, Validators.required),
      emergencyLastName: new FormControl<string|null>(null, Validators.required),
      emergencyRelationship: new FormControl<string|null>(null),
      emergencyMobile: new FormControl<string|null>(null, [Validators.required, Validators.pattern(/^(\+\d{2} \d{3} \d{3} \d{3}|\d{10}|\d{4} \d{3} \d{3}|\+\d{11})$/)])
    }),
    emergencyInformation: new FormControl<string|null>(null)
  })

  constructor(private fb: FormBuilder, private toasterService: ToasterService, private studentService: StudentService) { }

  ngOnInit(): void {
    if (this.student) {
      let date: string | null = null;

      if (this.student.dateOfBirth) {
        const studentDob = new Date(this.student.dateOfBirth)
        const day = studentDob.getDate().toString().padStart(2, '0');
        const month = (studentDob.getMonth() + 1).toString().padStart(2, '0');
        const year = studentDob.getFullYear().toString();
        date = `${year}-${month}-${day}`;
      }
      this.studentDetailsForm.setValue({
        firstName: this.student.firstName || '',
        lastName: this.student.lastName || '',
        email: this.student.email|| '',
        mobile: this.student.mobile || '',
        dateOfBirth: date || '',
        emergencyContact: {
          emergencyFirstName: this.student.emergencyFirstName || '',
          emergencyLastName: this.student.emergencyLastName || '',
          emergencyRelationship: this.student.emergencyRelationship || '',
          emergencyMobile: this.student.emergencyMobile || ''
        },
        emergencyInformation: this.student.emergencyInformation || ''
      });
      this.studentDetailsForm.get('email')?.disable();
      this.studentDetailsForm.get('dateofBirth')?.disable();
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.studentDetailsForm.valid) {
      this.submitting = true;
      this.studentService.updateStudentDetail(this.studentDetailsForm.value).subscribe({
        next: () => {
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Details updated'});
          this.studentDetailsForm.markAsPristine();
          this.submitted = false;
          this.submitting = false;
        },
        error: (error) => {
          this.toasterService.addToaster({type: ToasterType.Error, message: error.error.message});
          this.submitting = false;
        }
      })
    }
  }
}